import {ChangeDetectionStrategy, Component, ContentChildren, QueryList} from '@angular/core'
import {StepComponent} from './step/step.component'

@Component({
  selector: 'cft-step-by-step',
  templateUrl: './step-by-step.component.html',
  styleUrls: ['./step-by-step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepByStepComponent {
  @ContentChildren(StepComponent) private steps!: QueryList<StepComponent>

  get numberOfSteps(): number {
    return this.steps.length
  }
}
