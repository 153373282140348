import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {StepByStepComponent} from './step-by-step.component'
import {StepComponent} from './step/step.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {RouterModule} from '@angular/router'
import {ButtonModule} from '../button/button.module'
import {DynamicIconModule} from '../dynamic-icon/dynamic-icon.module'

@NgModule({
  declarations: [StepByStepComponent, StepComponent],
  exports: [StepByStepComponent, StepComponent],
  imports: [CommonModule, FontAwesomeModule, RouterModule, ButtonModule, DynamicIconModule],
})
export class StepByStepModule {}
