<div class="step">
  <div class="mr-xs">
    @if (number) {
      <div class="number">{{ number }}</div>
    }
    <div class="flex flex-shrink-0 items-center">
      <div class="circle">
        @if (isIconDefinition(icon)) {
          <fa-icon [icon]="asIconDefinition(icon)"></fa-icon>
        } @else {
          <cft-dynamic-icon [icon]="asDynamicIcon(icon)"></cft-dynamic-icon>
        }
      </div>
    </div>
  </div>
  <div class="step-text">
    <div class="heading-md" [innerHTML]="title"></div>
    <div class="mt-2xs text-sm" [innerHTML]="description"></div>
    @if (callToAction) {
      <cft-call-to-action
        [callToAction]="callToAction"
        [callToActionStyle]="callToActionStyle ?? 'button'"
        [anchorId]="callToActionId"
        class="mt-sm block"
      ></cft-call-to-action>
    }
  </div>
</div>
