import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {ApplicationRoute} from '../../utils/application.route'
import {CallToActionStyle, CallToActionType} from '../../button/call-to-action/call-to-action.component'

@Component({
  selector: 'cft-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent {
  @Input() number?: number
  @Input() title!: string
  @Input() description?: string
  @Input() icon!: {name: string; collection: string} | IconDefinition
  @Input() callToActionId?: string
  @Input() callToAction?: {label: string; type: CallToActionType; value: ApplicationRoute | string}
  @Input() callToActionStyle?: CallToActionStyle

  isIconDefinition(arg: object): arg is IconDefinition {
    return 'icon' in arg
  }

  asIconDefinition(i: {name: string; collection: string} | IconDefinition) {
    return i as IconDefinition
  }

  asDynamicIcon(i: {name: string; collection: string} | IconDefinition) {
    return i as {name: string; collection: string}
  }
}
